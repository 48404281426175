<template>
  <v-container fluid fill-height style='height: 90vh' class='pa-0'>
    <v-row no-gutters align='center' justify='center'>
      <v-card flat color='#fff' width='470'>
        <v-card-text>
          <v-form ref='formIsValid' v-model='formIsValid' lazy-validation>
            <v-text-field
              v-model='user.currentPassword'
              :rules='[rules.required]'
              :label='$t("CURRENT_PASSWORD")'
              type='password'
            />

            <v-text-field
              v-model='user.password'
              :rules='[rules.required]'
              :label='$t("NEW_PASSWORD")'
              :type='showPassword ? "text" : "password"'
              :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"'
              @click:append='togglePasswordVisibility'
            />
            <v-text-field
              v-model='user.confirmPassword'
              :rules='[rules.required, rules.passwordMatch]'
              :label='$t("CONFIRM_PASSWORD")'
              :type='showPassword ? "text" : "password"'
              :append-icon='showPassword ? "mdi-eye" : "mdi-eye-off"'
              @click:append='togglePasswordVisibility'
            />
          </v-form>

          <v-btn block color='secondary' class='mt-5' @click='save'>
            {{$t('SAVE')}}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'ProfileChangePassword',
    data: function () {
      return {
        formIsValid: true,
        rules: {
          required: (value) => !!value || this.$t('REQUIRED'),
          passwordMatch: (value) => (value === this.user.password) || this.$t('PASSWORDS_ENTERED_DO_NOT_MATCH'),
        },
        showPassword: false,
        user: {
          currentPassword: undefined,
          password: undefined,
          confirmPassword: undefined,
        },
      };
    },
    methods: {
      togglePasswordVisibility: function () {
        this.showPassword = !this.showPassword;
      },
      save: async function () {
        this.formIsValid = this.$refs.formIsValid.validate();
        if (!this.formIsValid) {
          return;
        }

        try {
          await axios({
            url: '/account-change-password',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              currentPassword: this.user.currentPassword,
              password: this.user.password,
              confirmPassword: this.user.confirmPassword,
            },
          });

          this.$emit('close');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
